import { Api } from './api';
import { ApiResponse } from 'apisauce';
import { TEmergencyContact, TIdentification } from './consumer-api';

interface TUserType {
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  id: string;
  name: string;
  description: string | null;
}

export interface TConsumer {
  active: boolean;
  address: string | null;
  birthdate: string;
  createdAt: string;
  createdBy: string;
  email: string | null;
  emergencyName: string | null;
  emergencyPhone: string | null;
  firstName: string;
  height: number;
  id: string;
  identification: string | null;
  isOwnUser: boolean;
  lastName: string;
  location: string | null;
  phone: number | null;
  postalCode: string | null;
  updatedAt: string;
  updatedBy: string;
  weight: number;
  additionalData?: any;
}

export interface TUserData {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  active?: boolean;
  id?: string;
  authId?: string;
  userType?: TUserType;
  contactOrigin?: string | null;
  firstName?: string;
  lastName?: string;
  username?: string | null;
  email?: string;
  phone?: string;
  birthdate?: string;
  weight?: number;
  height?: number;
  address?: string;
  location?: string;
  postalCode?: string;
  verified?: boolean;
  identification?: TIdentification;
  emergencyContact?: TEmergencyContact;
  consumers?: TConsumer[];
}

export class UserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async userExistsByEmail(email: string): Promise<boolean | undefined> {
    const response: ApiResponse<boolean> = await this.api.apisauce.post(
      'user/check-email',
      { email }
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getUser(): Promise<TUserData | undefined> {
    await this.api.getRequestConfig(); //TODO: revisar porque tengo que hacer este getRequestConfig para q se setee el token.
    const response: ApiResponse<TUserData> = await this.api.apisauce.get(
      'user/me'
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async updateUser(userData: any): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `user`,
      userData
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async deleteUser(body: { reason: string }): Promise<any> {
    await this.api.getRequestConfig();
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `email/delete-account-request`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response;
  }
}
