import { ApiResponse } from 'apisauce';
import { Api } from './api';

interface UpdateConsumerResponse {
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  id: string;
  user: {
    id: string;
  };
  identificationType?: {
    id: string;
    name: string;
    regex?: string;
  };
  firstName: string;
  lastName: string;
  birthdate: Date;
  weight: number;
  height: number;
  identification: string;
  phone: string;
  address: string;
  location: string;
  postalCode: string;
  email: string;
  active?: boolean;
  isOwnUser?: boolean;
}

export type TIdentification = {
  number: string;
  type: string;
};

export interface TEmergencyContact {
  phone?: string;
  fullName?: string;
}

export type TConsumer = {
  id?: string;
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  weight?: number;
  height?: number;
  identification?: TIdentification;
  emergencyContact?: TEmergencyContact;
  phone?: string;
  address?: string;
  location?: string;
  postalCode?: string;
  email?: string;
  isOwnUser?: boolean;
  hasFilledData?: boolean;
  [key: string]: any;
};

export class ConsumerApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getConsumer(
    consumerId: string
  ): Promise<UpdateConsumerResponse | undefined> {
    await this.api.getRequestConfig();
    const response: ApiResponse<UpdateConsumerResponse> =
      await this.api.apisauce.get(`consumer/${consumerId}`, {});
    if (!response.ok) {
      response.originalError;
    }
    return response.data;
  }

  async updateConsumer(
    consumerId: string | undefined,
    customerInfo: TConsumer
  ): Promise<UpdateConsumerResponse | undefined> {
    await this.api.getRequestConfig();
    const response: ApiResponse<UpdateConsumerResponse> =
      await this.api.apisauce.patch(`consumer/${consumerId}`, customerInfo);
    if (!response.ok) {
      response.originalError;
    }
    return response.data;
  }
}
